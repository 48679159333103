<template>
  <div>
    <content-template
      :menuName="`${$t('users.expertiseDetail')} ${$t('general.for')} ${expertise.name}`"
      :newButton="true"
      @moveToForm="moveToForm()"
      :backButton="true"
      @backToPage="moveToOther('Expertise')"
    >
      <template #table-header>
        <span
          :class="`s-mb-30 ${isMobile ? 's-fullWidth' : 's-flex s-mt--65'}`"
        >
          <Input
            class="s-ml-auto"
            label=""
            :placeholder="$t('general.search')"
            v-model="request.search"
            @keyup="setTime()"
            @keydown="clearTime()"
            @keydown.tab="setTime()"
            @keyup.tab="clearTime()"
            @keypress.enter="getData()"
          />
        </span>
      </template>

      <template #table>
        <TableCustom
          :columns="columns"
          :request="request"
          :fetchData="fetchData"
          :data="rows"
          @setSortBy="setSortBy"
          @setShow="setShow"
          @getData="getData"
          @mouseleave="popup = null"
          @togglePopup="togglePopup"
        >
          <template #index="props">
            {{ curNumber(props.index) }}
          </template>

          <template #created_at="props">
            {{ convertDate(props.row.created_at) }}
          </template>

          <template #action="props">
            <div class="r-menu">
              <img
                class="r-isClickAble"
                :src="require('@/assets/icons/others/more.svg')"
              />
              <div
                class="r-menu-items"
                v-if="popup == curNumber(props.index)"
                @mouseleave="popup = -1"
              >
                <div
                  class="r-menu-item s-center-flex"
                  @click.prevent="setUpdate(props.row)"
                >
                  {{ $t("general.update") }}
                </div>
                <div
                  class="r-menu-item s-text-red s-center-flex"
                  @click.prevent="openDialog('Delete', props.row)"
                >
                  {{ $t("general.delete") }}
                </div>
              </div>
            </div>
          </template>
        </TableCustom>
      </template>
    </content-template>

    <Modal v-if="modal == 'Delete'" :title="$t('general.warning')">
      <template #modalContent>
        <p>{{ $t("general.modalDelete") }}</p>

        <div class="s-mtb-20 s-flex">
          <div class="s-ml-auto">
            <Button color="error" @click.prevent="onDelete">
              {{ $t("general.delete") }}
            </Button>
          </div>
        </div>
      </template>
    </Modal>

    <Modal v-if="modal == 'ExpertiseDetail'" :title="modal_title">
      <template #modalContent>
        <Input
          v-model="expertise_detail"
          :label="$t('users.expertiseDetail')"
          class="s-mb-20"
          :error="validation_expertise"
        />

        <div class="s-mtb-20 s-flex">
          <div class="s-ml-auto">
            <Button
              class="s-ml-20"
              @click.prevent="onFinish"
              :isLoading="isLoading"
              >{{ $t("general.submit") }}</Button
            >
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import tableMixins from "@/mixins/tableMixins";
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";
import { convertDate } from "@/common/timeformat";

export default {
  components: { ContentTemplate },
  mixins: [tableMixins, form],
  data() {
    return {
      columns: [
        {
          label: "No",
          fieldName: "index",
          isSortable: false,
          class: "r-table-firstColumn",
        },
        {
          label: "general.name",
          fieldName: "name",
          isSortable: true,
          class: "",
        },
        {
          label: "general.registrationDate",
          fieldName: "created_at",
          isSortable: true,
          class: "",
        },
        {
          label: "general.author",
          fieldName: "created_by",
          isSortable: true,
          class: "",
        },
        {
          label: "general.action",
          isSortable: false,
          fieldName: "action",
          class: "r-table-actionColumn",
        },
      ],
      current_id: "",
      expertise_detail: "",
      validation_expertise: false,
      expertise_id: "",
      modal_title: "",
      modal_type: "New",
      isLoading: false,
    };
  },
  mounted() {
    this.expertise_id = this.$route.params.id;
    this.initData();
  },

  computed: {
    ...mapGetters({
      rows: "expertisedetail/getExpertiseDetail",
      role_type: "auth/getRole",
      request: "expertisedetail/getRequest",
      expertise: "expertise/getExpertise",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    modal() {
      return this.$store.getters["getModal"];
    },
  },

  methods: {
    ...mapActions({
      setExpertiseDetail: "expertisedetail/setExpertiseDetailData",
      setExpertiseSingleData: "expertise/setExpertiseSingleData",
      setModal: "setModal",
      delete_expertiseDetail: "expertisedetail/deleteExpertiseDetailData",
      postExpertiseDetailData: "expertisedetail/postExpertiseDetailData",
      putExpertiseDetailData: "expertisedetail/putExpertiseDetailData",
    }),
    async initData() {
      this.setLoadingPage(true);
      await this.getData();
      this.setLoadingPage(false);
    },
    async onDelete() {
      await this.delete_expertiseDetail(this.current_id);
      this.setModal(false);
      this.setExpertiseDetail(this.expertise_id);
      this.setExpertiseSingleData(this.expertise_id);
    },
    async getData(type = 0) {
      this.fetchData = true;
      if (type === 0) this.request.page = 1;
      else this.request.page += type;
      await this.setExpertiseSingleData(this.expertise_id);
      await this.setExpertiseDetail(this.expertise_id);
      this.fetchData = false;
    },

    async onFinish() {
      if (this.expertise_detail == "") this.validation_expertise = true;
      else this.validation_expertise = false;

      if (this.validation_expertise == false) {
        this.isLoading = true;
        if (this.modal_type == "New") {
          const data = {
            expertise_id: this.expertise_id,
            name: this.expertise_detail,
          };
          await this.postExpertiseDetailData(data);
        } else {
          await this.putExpertiseDetailData({
            id: this.current_id,
            name: this.expertise_detail,
          });
        }
        this.setModal(null);
        this.setExpertiseDetail(this.expertise_id);
        this.expertise_detail = "";
        if (this.$store.getters[`expertisedetail/getStatus`] == 0) {
          this.showSnackbar({
            type: "error",
            text: `${this.$t("general.addEditFailed")}`,
          });
        } else {
          this.showSnackbar({
            type: "success",
            text: `${this.$t("general.editSuccess")}`,
          });
        }
      }
      this.isLoading = false;
    },

    moveToForm() {
      this.modal_type = "New";
      this.openDialog("ExpertiseDetail", null);
    },

    setUpdate(data) {
      this.modal_type = "Edit";
      this.openDialog("ExpertiseDetail", data);
      this.modal_title = this.$t("expert.editExpertiseDetail");
      this.expertise_detail = data.name;
    },

    openDialog(state, data) {
      this.modal_title = this.$t("expert.addExpertiseDetail");
      this.setModal(state);
      if (this.modal_type == "Edit") this.current_id = data.id;
    },
    convertDate(value) {
      return convertDate(value);
    },
  },
};
</script>
